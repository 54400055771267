import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import SectionsContainer from '../../../components/SectionsContainer'

const BreakoutSessions = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaq(
        filter: { type: { eq: "Virtual Event" } }
        sort: { fields: order }
      ) {
        edges {
          node {
            id
            title
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)

  const FAQs = data.allContentfulFaq.edges

  return (
    <SectionsContainer>
      <section className="padded-section wrap body-content">
        <h3 className="headline--reunion center-align">Policies</h3>
        <ol>
          <li>LOVE Plus Tickets are only available while supplies last.</li>
          <li>Limit one LOVE Plus or Basic Ticket purchase per Artist. Additional ticket purchases will be canceled.</li>
          <li><strong>No refunds, exchanges or returns on LOVE Gift items.</strong></li>
          <li><strong>All sales are final. LOVE tickets are non-transferable and non-refundable.</strong></li>
        </ol>
      </section>
      <section className="padded-section wrap">
        <h3 className="headline--reunion center-align">FAQ</h3>
        {FAQs.map(({ node: QAndA }) => (
          <div key={QAndA.id}>
            <h4 className="section-label">{QAndA.title}</h4>
            <div
              className="body-content"
              dangerouslySetInnerHTML={{
                __html: QAndA.body.childMarkdownRemark.html,
              }}
            />
          </div>
        ))}
      </section>
    </SectionsContainer>
  )
}

export default BreakoutSessions
