/* eslint-disable react/display-name */
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  > section {
    max-width: ${props => props.theme.containerWidths.medium};
    margin-left: auto;
    margin-right: auto;
  }

  .headline--small {
    ~ .headline--small {
      margin-top: 40px;
    }
  }

  .body-content {
    ul {
      margin-bottom: 0;
    }
  }

  .padded-section {
    .section-label {
      margin-bottom: 5px;
    }

    > div + div {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    section {
      + .padded-section {
        padding-top: 0;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    .headline--small {
      ~ .headline--small {
        margin-top: 60px;
      }
    }
  }
`

const SectionsContainer = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
)
export default SectionsContainer
